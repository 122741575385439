.about {
  font-family: 'Roboto', Arial, sans-serif;
  color: #333;
  padding: 40px 20px;
}

.about-header {
  text-align: center;
  background: linear-gradient(135deg, #005f73, #79BAC1);
  padding: 50px 20px;
  color: white;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.about-header h1 {
  font-size: 2.5em;
  margin: 0;
}

.about-content,
.about-developer {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 20px;
}

.about-content p,
.about-developer p {
  font-size: 1.2em;
  line-height: 1.6;
  margin: 20px 0;
}

.about-developer h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #005f73; /* Matching blue color for headings */
}

