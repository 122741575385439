.contact {
  font-family: 'Roboto', Arial, sans-serif;
  color: #333;
  padding: 40px 20px;
}

.contact-header {
  text-align: center;
  background: linear-gradient(135deg, #005f73, #79BAC1);
  padding: 50px 20px;
  color: white;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.contact-header h1 {
  font-size: 2.5em;
  margin: 0;
}

.contact-content {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 20px;
}

.contact-content p {
  font-size: 1.2em;
  line-height: 1.6;
  margin: 20px 0;
}

.contact-content a {
  color: #005f73; /* Matching blue color for links */
  text-decoration: none;
}

.contact-content a:hover {
  text-decoration: underline;
}

.contact-form {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form label {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  color: #005f73; /* Matching blue color for labels */
}

.contact-form input,
.contact-form textarea {
  width: calc(100% - 20px); /* Adjust width to account for padding */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.contact-form button {
  background-color: #005f73;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.contact-form button:hover {
  background-color: #004150;
}
