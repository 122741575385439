/* Adjust the default styles of react-burger-menu to fit your needs */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px; /* Adjusted position */
  top: 20px;
  z-index: 1000; /* Ensure the button is always on top */
}

.bm-burger-bars {
    background: #FFA500; /* Light green background color */
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #333; /* Darker cross button for contrast */
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: #f5f5f5; /* Light background color */
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list {
  color: #333; /* Dark text color for contrast */
}

.bm-item {
  display: block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #333; /* Dark text color */
  transition: color 0.2s;
}

.bm-item:hover {
  color: #005f73; /* Color on hover */
}

/* Ensure the main content is not pushed to the side */
.main-content {
  padding: 20px;
}
