.home {
  font-family: 'Roboto', Arial, sans-serif;
  text-align: center;
  color: #333;
}

.home-header {
  background: linear-gradient(135deg, #005f73, #79BAC1); /* Gradient background */
  padding: 100px 20px; /* Increased padding for a larger header */
  color: white;
  text-align: center;
  border-bottom-left-radius: 50px; /* Rounded bottom corners */
  border-bottom-right-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.home-header h1 {
  font-size: 3em;
  margin: 0;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Text shadow for better readability */
}

.home-header p {
  font-size: 1.5em;
  margin: 20px 0;
  font-weight: 400;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); /* Text shadow for better readability */
}

.description {
  padding: 40px 20px;
  background-color: #f5f5f5; /* Light Gray */
  margin: 40px auto; /* Center the section with some margin */
  max-width: 800px; /* Limit the width for better readability */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.description-content p {
  font-size: 1.3em;
  line-height: 1.6;
  margin: 0;
  color: #333; /* Darker color for better readability */
}

.features {
  padding: 50px 20px;
  background-color: white; /* White background for features section */
}

.features h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.features-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.feature-card {
  background-color: #e0f7fa; /* Light Cyan */
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.feature-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.feature-card p {
  font-size: 1.1em;
  font-weight: 700;
  margin-top: 10px;
  color: #005f73; /* Matching blue color for text */
}

.cta {
  padding: 50px 20px;
  background-color: #f4f4f4; /* Light Gray */
  text-align: center;
}

.cta h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.cta p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.cta-button {
  background-color: #005f73;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #004150;
}

.testimonials {
  padding: 50px 20px;
  background-color: #2A7886; /* Dark Green */
  color: white;
}

.testimonials h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.testimonials-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.testimonial {
  background-color: #226064;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.testimonial p {
  font-size: 1em;
  margin: 0 0 10px;
}

.testimonial h4 {
  font-size: 1.2em;
  margin: 0;
}

.home-footer {
  background-color: #005f73; /* New Blue */
  color: white;
  padding: 20px;
  text-align: center;
}

.footer-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}
